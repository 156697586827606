import React, { useEffect, useState } from 'react';
import { getBookings } from '../../../helper/api.helper';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import AccordionItem from './accordion-item/AccordionItem';
import toast from 'react-hot-toast';
import Loader from '../../../shared/components/loader/Loader';
import Overview from './overview/Overview';
import { getProgressDataFromDb } from './overview/overview.service';

const MyBookings = () => {
  const [schedule, setSchedule] = useState({});
  const [bookingsSchedule, setBookingsSchedule] = useState([]);
  const [lastBookingTimestamp, setLastBookingTimestamp] = useState(null);
  const [hasMore, setHasMore] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [progressData, setProgressData] = useState({});
  const [incompleteMandatorySteps, setIncompleteMandatorySteps] = useState(0);

  const getProgressData = () => {
    getProgressDataFromDb()
      .then((resData) => {
        const data = {
          steps: [
            {
              isMandatory: true,
              isComplete: resData.education && resData.experience
            },
            {
              isMandatory: true,
              isComplete: resData.availability
            },
            {
              isMandatory: true,
              isComplete: resData.service
            },
            {
              isMandatory: true,
              isComplete: resData.domain
            },
            {
              isMandatory: true,
              isComplete: resData.branding
            }
          ]
        };
        setProgressData(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getProgressData();
  }, []);

  useEffect(() => {
    setIncompleteMandatorySteps(
      progressData?.steps?.some((step) => step.isMandatory && !step.isComplete)
    );
  }, [progressData]);

  const groupBookingsByDate = (bookings) => {
    return bookings.reduce((acc, booking) => {
      const dateKey = format(new Date(booking.meetingStart), 'yyyy-MM-dd');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(booking);
      return acc;
    }, {});
  };

  const getMyBookings = () => {
    setLoading(true);
    getBookings({ lastTimestamp: lastBookingTimestamp })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          if (res.data.bookings.length > 0) {
            const updatedBookings = bookingsSchedule
              ? [...bookingsSchedule, ...res.data.bookings]
              : res.data.bookings;
            setBookingsSchedule(updatedBookings.sort((a, b) => b.meetingStart - a.meetingStart));
          }
          // setSchedule(groupBookingsByDate(res.data.bookings));
          // setLastBookingTimestamp(res.data.bookings[res.data.bookings.length - 1].meetingStart);
          setHasMore(res.data.hasMore);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getMyBookings();
  }, []);

  useEffect(() => {
    setLastBookingTimestamp(bookingsSchedule?.[bookingsSchedule?.length - 1]?.meetingStart);
    setSchedule(groupBookingsByDate(bookingsSchedule));
  }, [bookingsSchedule]);

  return (
    <div className="bookings-container ">
      {isLoading ? <Loader /> : null}
      {incompleteMandatorySteps && <Overview />}
      <InfiniteScroll
        dataLength={bookingsSchedule ? bookingsSchedule.length : 0}
        next={getMyBookings}
        hasMore={hasMore}
        loader={<p className="optional-text margin-top">Loading...</p>}
        endMessage={
          bookingsSchedule && bookingsSchedule.length > 0 ? (
            <p className="optional-text margin-top">That&apos;s All !</p>
          ) : (
            <p className="optional-text margin-top">No Bookings Yet !</p>
          )
        }>
        {schedule &&
          Object.keys(schedule)?.map((day, i) => (
            <AccordionItem key={i} schedule={schedule} day={day} />
          ))}
      </InfiniteScroll>
      <style jsx>{`
        .bookings-container {
          margin-top: -10px;
          width: 100%;
        }
        .optional-text {
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default MyBookings;
