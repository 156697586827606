import { TextField } from '@mui/material';
import React from 'react';

const BufferTime = ({ service, handleServiceChange }) => {
  return (
    <>
      <h5 className="margin-top--sm" style={{ color: 'black' }}>
        Buffer Time
      </h5>
      <TextField
        size="small"
        name="preEventBuffer"
        label="Before Event (min)"
        type="number"
        value={service.preEventBuffer}
        onChange={(e) => handleServiceChange(e, service.id)}
        // required
        sx={{ maxWidth: '200px', marginBottom: '1rem' }}
        inputProps={{ min: 0 }}
      />
      <TextField
        size="small"
        name="postEventBuffer"
        label="After Event (min)"
        type="number"
        value={service.postEventBuffer}
        onChange={(e) => handleServiceChange(e, service.id)}
        // required
        sx={{ maxWidth: '200px', marginBottom: '1rem' }}
        inputProps={{ min: 0 }}
      />
    </>
  );
};

export default BufferTime;
