import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import MyIntegrations from './my-integrations/MyIntegrations';

const Integrations = () => {
  return (
    <>
      <Helmet>
        <title>Integrations - DeTalks</title>
      </Helmet>
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route path="/" element={<MyIntegrations />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Integrations;
