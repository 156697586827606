import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Step = ({ steps }) => {
  return (
    <div className="wrapper margin-top--lg">
      <div className="step-row header flex-container gray-text">
        <p className="step-column step">Steps</p>
        <p className="step-column type">Type</p>
        <p className="step-column status">Status</p>
        <p className="step-column actions">Actions</p>
      </div>
      <hr />
      {steps?.map(({ title, icon, isMandatory, isComplete, link }, index) => (
        <div
          key={title}
          className="step-row flex-container align-center margin-top--sm margin-bottom--sm">
          <div className="step-column steps flex-container align-center">
            <div
              className={`flex-container align-center icon ${
                isComplete ? 'completed' : 'not-completed'
              }`}>
              {icon}
            </div>
            <p className="title">
              {title}
              {isMandatory && '*'}
            </p>
          </div>
          <p className="step-column type">{isMandatory ? 'Mandatory' : 'Recommended'}</p>
          <p className={`step-column status ${isComplete ? 'completed' : 'not-completed'}`}>
            {isComplete ? 'Complete' : 'Incomplete'}
          </p>
          <div className="step-column action">
            {isComplete ? (
              <Link to={link}>
                <Button color="primary" fullWidth variant="text">
                  Edit
                </Button>
              </Link>
            ) : (
              <Link to={link}>
                <Button color="primary" fullWidth variant="contained">
                  Complete
                </Button>
              </Link>
            )}
          </div>
        </div>
      ))}
      <style jsx>{`
        .header {
          font-weight: 500;
        }
        .step-column {
          width: 25%;
        }
        step-row {
          padding-right: 10px;
        }
        .title {
          margin-left: 10px;
        }
        .icon {
          color: var(--primary-color);
        }
        .not-completed {
          color: var(--warning-color);
        }
        .completed {
          color: var(--success-color);
        }
        @media only screen and (max-width: 800px) {
          .steps,
          .step {
            width: 70%;
          }
          .action,
          .actions {
            width: 30%;
          }
          .type,
          .status {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default Step;
