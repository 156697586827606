import { doc, getDoc, setDoc } from 'firebase/firestore';
import { database } from '../../../helper/firebase.helper';

export const getSettingsDataFromDb = async (orgId) => {
  const orgRef = doc(database, `organisations/${orgId}`);
  const orgDocSnap = await getDoc(orgRef);
  return orgDocSnap.data();
};

export const storeSettingsToDb = async (orgId, data) => {
  const orgDocRef = doc(database, `organisations/${orgId}`);
  return await setDoc(orgDocRef, data, { merge: true });
};
