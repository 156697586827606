import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';

const ServiceName = ({ service, handleServiceChange }) => {
  return (
    <>
      <TextField
        size="small"
        name="title"
        label="Service type"
        value={service.title}
        onChange={(e) => handleServiceChange(e, service.id)}
        required
        sx={{ marginBottom: '1rem', marginTop: '1rem' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PsychologyOutlinedIcon sx={{ transform: 'scaleX(-1)', color: 'black' }} />
            </InputAdornment>
          )
        }}
        placeholder="Enter service name"
      />
    </>
  );
};

export default ServiceName;
