import { IconButton } from '@mui/material';
import React from 'react';
import ServiceName from './service-name/ServiceName';
import Duration from './duration/Duration';
import BufferTime from './buffer-time/BufferTime';
import SelectLocations from './select-locations/SelectLocations';
import NoNewClients from './no-new-clients/NoNewClients';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMatch } from 'react-router';

const ServiceItem = ({ i, service, locations, setServices, setServiceChanged }) => {
  const register = useMatch('/register/about-me');

  const handleServiceChange = (e, serviceId) => {
    const { name, value } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId ? { ...service, [name]: value } : service
      )
    );
    setServiceChanged(true);
  };

  const handleLocationChange = (e, serviceId, location) => {
    const { checked } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) => {
        if (service.id === serviceId) {
          const updatedLocations = checked
            ? [...service.locations, { id: location.id, name: location.name, price: 0 }]
            : service.locations.filter((loc) => loc.id !== location.id);
          return { ...service, locations: updatedLocations };
        }
        return service;
      })
    );
    setServiceChanged(true);
  };

  const handleAcceptNewClients = (e, serviceId) => {
    const { checked } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId ? { ...service, noNewClients: checked } : service
      )
    );
    setServiceChanged(true);
  };

  const handleLocationPriceChange = (e, serviceId, locationId) => {
    const { value } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId
          ? {
              ...service,
              locations: service.locations.map((loc) =>
                loc.id === locationId ? { ...loc, price: value } : loc
              )
            }
          : service
      )
    );
    setServiceChanged(true);
  };

  const handleDeleteService = (serviceId) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId ? { ...service, active: false } : service
      )
    );
    setServiceChanged(true);
  };
  return (
    <div>
      <div
        className="flex-container justify-between "
        style={{
          // maxWidth: "800px",
          borderBottom: '1px solid var(--border-gray)'
        }}>
        <h4
          style={{ marginTop: register ? '-5px' : null }}
          className={register ? null : 'margin-top--xxs'}>
          Service {i + 1}
        </h4>
        {!register && (
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteService(service.id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
      {/* <p
              style={{

                borderBottom: "1px solid var(--border-gray)"
              }}
            ></p> */}
      <div
        className="flex-container direction-column margin-top--xxxs"
        key={service.id}
        style={{ marginBottom: '20px', maxWidth: '500px' }}>
        <ServiceName service={service} handleServiceChange={handleServiceChange} />

        <Duration service={service} handleServiceChange={handleServiceChange} />

        <BufferTime service={service} handleServiceChange={handleServiceChange} />

        <SelectLocations
          service={service}
          locations={locations}
          handleLocationChange={handleLocationChange}
          handleLocationPriceChange={handleLocationPriceChange}
        />
        {/* <NoNewClients service={service} handleAcceptNewClients={handleAcceptNewClients} /> */}
      </div>
    </div>
  );
};

export default ServiceItem;
