import React from 'react';
import Domain from './domain/Domain';
import Branding from './branding/Branding';
import { Helmet } from 'react-helmet';

const DomainAndBranding = () => {
  return (
    <div>
      <Helmet>
        <title>Domain & Branding - DeTalks</title>
      </Helmet>
      <Domain />
      <Branding />
    </div>
  );
};

export default DomainAndBranding;
