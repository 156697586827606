import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import toast from 'react-hot-toast';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../../helper/firebase.helper';
import { useStoreState } from 'easy-peasy';
import { meetingTypeEnum } from '../../../helper/constants.helper';
import Loader from '../../../shared/components/loader/Loader';
import DayItem from '../../../dashboard/availability/DayItem';
import LocationDetails from '../../../dashboard/availability/LocationDetails';

const Availability = ({ goNext }) => {
  const practitioner = useStoreState((state) => state.user);
  const newLocation = {
    id: doc(collection(database, 'practitioners', practitioner?.uid, 'locations')).id,
    meetingType: meetingTypeEnum.TELEHEALTH,
    location: 'Personal meeting link',
    locationLink: '',
    meetingLink: '',
    minimumNotice: 2,
    active: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    availability: [
      {
        day: 0,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 1,
        periods: [{ startTime: '10:00 AM', endTime: '4:00 PM' }],
        isActive: true
      },
      {
        day: 2,
        periods: [{ startTime: '10:00 AM', endTime: '4:00 PM' }],
        isActive: true
      },
      {
        day: 3,
        periods: [{ startTime: '10:00 AM', endTime: '4:00 PM' }],
        isActive: true
      },
      {
        day: 4,
        periods: [{ startTime: '10:00 AM', endTime: '4:00 PM' }],
        isActive: true
      },
      {
        day: 5,
        periods: [{ startTime: '10:00 AM', endTime: '4:00 PM' }],
        isActive: true
      },
      {
        day: 6,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      }
    ]
  };
  const [locations, setLocations] = useState([newLocation]);
  const [isLoading, setLoading] = useState(false);
  const [availabilityChanged, setAvailabilityChanged] = useState(false);

  const fetchAvailability = async () => {
    setLoading(true);
    const data = await getDocs(
      query(
        collection(database, 'practitioners', practitioner?.uid, 'locations'),
        where('active', '==', true)
      )
    ).then((docs) => {
      const res = [];
      docs.forEach((doc) => res.push(doc.data()));
      setLoading(false);
      return res;
    });
    if (data && data.length) {
      setLocations(data);
      setLoading(false);
    } else {
      setLocations([newLocation]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailability();
  }, []);

  const saveAvailabilityToDB = async (locations) => {
    await Promise.all(
      locations.map((location, i) =>
        setDoc(doc(database, 'practitioners', practitioner?.uid, 'locations', location.id), {
          ...location,
          order: i
        })
      )
    );
    const progressDocRef = doc(database, `practitioners/${practitioner?.uid}/details/progress`);
    await setDoc(progressDocRef, { availability: true }, { merge: true });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const invalidLocationLinks = locations.filter(
      (item) => item.locationLink && !item.locationLink.startsWith('https://maps.app.goo.gl/')
    );
    // const invalidMeetingLinks = locations.filter(
    //   (item) => item.meetingLink && !item.meetingLink.startsWith('https://meet.google.com/')
    // );
    if (invalidLocationLinks.length > 0) {
      invalidLocationLinks.forEach((item) => {
        toast.error(`Invalid Google Maps link for location: ${item.location}`);
      });
      return;
    }
    // if (invalidMeetingLinks.length > 0) {
    //   invalidMeetingLinks.forEach((item) => {
    //     toast.error(`Invalid Google Meet link for location: ${item.location}`);
    //   });
    //   return;
    // }
    toast.promise(saveAvailabilityToDB(locations), {
      loading: 'Saving availability',
      success: 'Availability saved !',
      error: (err) => `Failed to save! ${err.message}. Please try again`
    });
    goNext();
    setAvailabilityChanged(false);
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <form onSubmit={handleSave}>
        {locations
          ?.sort((a, b) => a.order - b.order)
          .map(
            (availabilityItem, index) =>
              availabilityItem?.active === true && (
                <div key={index} className="margin-bottom--lg">
                  <div className="flex-container justify-between heading-div">
                    <h4 style={{ marginTop: '-5px' }}>Location {index + 1} Availability</h4>
                  </div>

                  <LocationDetails
                    availabilityItem={availabilityItem}
                    setLocations={setLocations}
                    index={index}
                    setAvailabilityChanged={setAvailabilityChanged}
                    locations={locations}
                  />
                  <h4 style={{ fontSize: '16px' }} className=" margin-top--lg">
                    Availability
                  </h4>
                  {availabilityItem?.availability?.map(({ day, periods, isActive }) => (
                    <DayItem
                      id={availabilityItem.id}
                      key={day}
                      day={day}
                      periods={periods}
                      setLocations={setLocations}
                      isActive={isActive}
                      // onDayChange={() =>
                      //   handleDayChange(availabilityItem.id, day)
                      // }
                      setAvailabilityChanged={setAvailabilityChanged}
                    />
                  ))}
                </div>
              )
          )}
        <p>You can add more locations later in your dashboard.</p>
        <Button
          // disabled={!availabilityChanged}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}>
          <p className="text--md">Next</p> <EastIcon style={{ marginLeft: '20px' }} />
        </Button>
      </form>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 0px 5px 2px 0px;
        }
        .content-div {
          padding: 0px 5px 15px 0px;
        }
      `}</style>
    </div>
  );
};

export default Availability;
