import React, { useEffect, useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';
import { collection, doc, setDoc } from 'firebase/firestore';
import { database } from '../../../helper/firebase.helper';
import { useStoreState } from 'easy-peasy';
import { getAllServices } from '../../../helper/api.helper';
import ServiceItem from '../../../dashboard/services/service-list/service-item/ServiceItem';

const Service = ({ goNext }) => {
  const practitioner = useStoreState((state) => state.user);
  const [services, setServices] = useState([
    {
      id: doc(collection(database, 'services')).id,
      title: '',
      time: '',
      active: true,
      preEventBuffer: 0,
      postEventBuffer: 0,
      locations: [],
      noNewClients: false
    }
  ]);
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [serviceChanged, setServiceChanged] = useState(false);

  const getServices = () => {
    setLoading(true);
    getAllServices()
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          // setServices(res.data.services);
          setLocations(res.data.locations);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const storeToDb = async (services) => {
    await Promise.all(
      services.map((service) =>
        setDoc(doc(database, 'services', service.id), {
          ...service,
          practitioner: practitioner.uid,
          updateTime: Date.now(),
          locations: service.locations?.map((location) => ({
            id: location.id,
            price: location.price
          }))
        })
      )
    );
    const progressDocRef = doc(database, `practitioners/${practitioner?.uid}/details/progress`);
    await setDoc(progressDocRef, { service: true }, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const invalidService = services.find((service) => service.locations.length === 0);

    if (invalidService) {
      toast.error(`Please select at least one location for the service: ${invalidService.title}`);
      return;
    }
    toast.promise(storeToDb(services), {
      success: 'Service saved!',
      loading: 'Saving ...',
      error: 'Failed to save!'
    });
    setServiceChanged(false);
    goNext();
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {services?.map(
          (service, i) =>
            service?.active === true && (
              <ServiceItem
                i={i}
                key={i}
                service={service}
                locations={locations}
                setServices={setServices}
                setServiceChanged={setServiceChanged}
              />
            )
        )}

        <p>You can add more services later in your dashboard.</p>

        <div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: '20px' }}>
            <p className="text--md">Next</p> <EastIcon style={{ marginLeft: '20px' }} />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Service;
