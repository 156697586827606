import { Fab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useStoreState } from 'easy-peasy';
import { getSettingsDataFromDb, storeSettingsToDb } from './settings.service';
import { Helmet } from 'react-helmet';
import EmailDomains from './email-domains/EmailDomains';

const Settings = () => {
  const [emailDomains, setEmailDomains] = useState([]);
  const [settingsChanged, setSettingsChanged] = useState(false);

  const practitionerData = useStoreState((state) => state.practitionerData);

  const saveSettings = async () => {
    try {
      await toast.promise(
        storeSettingsToDb(practitionerData.own_organisation, { emailDomains: emailDomains }),
        {
          loading: 'Saving...',
          success: 'Saved!',
          error: `Couldn't save`
        }
      );
      const updatedData = await getSettingsDataFromDb(practitionerData.own_organisation);
      setEmailDomains(updatedData.emailDomains);
      setSettingsChanged(false);
    } catch (error) {
      toast.error('Error saving data:', error.message);
    }
  };

  const getSettingsData = async () => {
    getSettingsDataFromDb(practitionerData.own_organisation).then((res) => {
      if (res) {
        setEmailDomains(res.emailDomains);
      }
    });
  };

  useEffect(() => {
    getSettingsData();
  }, [practitionerData]);

  return (
    <div className="settings-container">
      <Helmet>
        <title>Settings - DeTalks</title>
      </Helmet>
      <EmailDomains
        setSettingsChanged={setSettingsChanged}
        setEmailDomains={setEmailDomains}
        emailDomains={emailDomains}
      />
      <div className="floating-button">
        <Fab
          style={{ width: '120px' }}
          variant="extended"
          color="primary"
          disabled={!settingsChanged}
          onClick={() => saveSettings()}>
          Save
        </Fab>
      </div>
      <style jsx>{`
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default Settings;
