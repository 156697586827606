import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React from 'react';

const SelectLocations = ({
  service,
  locations,
  handleLocationChange,
  handleLocationPriceChange
}) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      {locations.map((location) => (
        <div
          key={location.id}
          style={{ marginTop: '0.6rem' }}
          className="flex-container justify-between">
          <FormControlLabel
            style={{
              maxWidth: `${window.innerWidth < 600 ? '200px' : '350px'}`,
              alignItems: 'flex-start'
            }}
            control={
              <Checkbox
                checked={service.locations.some((loc) => loc.id === location.id)}
                onChange={(e) => handleLocationChange(e, service.id, location)}
                style={{ paddingTop: 0 }}
              />
            }
            label={location.name}
          />
          {service.locations.some((loc) => loc.id === location.id) && (
            <TextField
              required
              size="small"
              label="Rate"
              type="number"
              value={service.locations.find((loc) => loc.id === location.id)?.price || ''}
              onChange={(e) => handleLocationPriceChange(e, service.id, location.id)}
              sx={{ width: '100px' }}
              placeholder="₹ 0"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SelectLocations;
