import { TextField } from '@mui/material';
import React from 'react';

const Duration = ({ service, handleServiceChange }) => {
  return (
    <>
      <TextField
        size="small"
        name="time"
        label="Duration (min)"
        type="number"
        value={service.time}
        onChange={(e) => handleServiceChange(e, service.id)}
        required
        sx={{ maxWidth: '200px', marginBottom: '1rem' }}
        inputProps={{ min: 0 }}
      />
    </>
  );
};

export default Duration;
