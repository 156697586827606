import React from 'react';
import { Controller } from 'react-hook-form';
import UploadPic from '../../../shared/components/upload-pic/UploadPic';

const Picture = ({ control, setCroppedImage }) => {
  return (
    <>
      <div className="heading-div ">
        <b>Personal</b>
      </div>
      <div className="profile-pic-container margin-bottom--lg">
        <Controller
          name="profilePicUrl"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <UploadPic
              name="Profile picture"
              helperText={[
                'Aspect ratio 1:1',
                'Tip: Use a professional picture. It helps in establishing credibility with the client.'
              ]}
              aspectRatioWidth={1}
              aspectRatioHeight={1}
              maxWidth="150px"
              setCropData={setCroppedImage('profilePic')}
              imageUrl={value}
            />
          )}
        />
      </div>
      <style jsx>{`

        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
          color:black;
        }
        .profile-pic-container {
          min-width: 80px;
          margin-top: 10px;
        }

        }
      `}</style>
    </>
  );
};

export default Picture;
