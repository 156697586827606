import React from 'react';
import psypackicon from '../../../assets/psypackicon.png';
import googlemeet from '../../../assets/googlemeet.png';
import googlecalendar from '../../../assets/googlecalendar.png';

const MyIntegrations = () => {
  return (
    <div className="integrations-container flex-container ">
      <div className="integration-item margin-top--sm">
        <img src={psypackicon} alt="PsyPAck Icon" />
        <h4 className="margin-top--xs">PsyPack Assessments</h4>
        <p>
          Integrate PsyPack to administer assessments, track progress and access detailed reports
          directly within DeTalks. Streamline your evaluation process effortlessly.
        </p>
        <p style={{ color: '#007aff' }}>Coming soon</p>
      </div>
      <div className="integration-item margin-top--sm">
        <img src={googlemeet} alt="Google Meet Icon" />

        <h4 className="margin-top--xs">Google Meet</h4>
        <p>
          Connect your account to schedule and host secure video sessions directly from DeTalks.
          Simplify virtual therapy with seamless integration.
        </p>
        <p style={{ color: '#007aff' }}>Coming soon</p>
      </div>
      <div className="integration-item margin-top--sm">
        <img src={googlecalendar} alt="Google Meet Icon" />

        <h4 className="margin-top--xs">Google Calendar</h4>
        <p>
          Seamlessly sync your appointments and manage your schedule effortlessly. Connect your
          account to ensure all your sessions are organized in one place.
        </p>
        <p style={{ color: '#007aff' }}>Coming soon</p>
      </div>

      <style jsx>{`
        .integrations-container {
          max-width: 1000px;
          flex-wrap: wrap;
        }
        .integration-item {
          max-width: 30%;
          margin-left: 10px;
          min-width: 200px;
          //   flex-basis: 30%;
          border-radius: 10px;
          border: 1px solid var(--border-gray);
          padding: 10px;
        }
        .integration-item img {
          width: 70px;
        }
      `}</style>
    </div>
  );
};

export default MyIntegrations;
