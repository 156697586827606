import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import InputAdornment from '@mui/material/InputAdornment';
import { useStoreState } from 'easy-peasy';
import { doc, setDoc } from 'firebase/firestore';
import { database } from '../../../helper/firebase.helper';
import { experienceOptions } from '../../../dashboard/profile/personal/shared/profile.service';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';

const Experience = ({ goNext }) => {
  const { handleSubmit, setValue, control } = useForm();
  const user = useStoreState((state) => state.user);
  const userId = user?.uid;

  const onSubmit = async (data) => {
    const personalDocRef = doc(database, `practitioners/${userId}/details/personal`);
    await setDoc(personalDocRef, data, { merge: true });
    const progressDocRef = doc(database, `practitioners/${userId}/details/progress`);
    await setDoc(progressDocRef, { experience: true }, { merge: true });
    goNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="margin-top--xs margin-bottom--md full-width" style={{ width: '300px' }}>
        <Controller
          name="experience.value"
          control={control}
          defaultValue={3}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl variant="outlined" size="small" fullWidth error={!!error} required>
              <InputLabel id="experience-label">Experience</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300
                    }
                  }
                }}
                // sx={{ maxWidth: 300 }}
                labelId="experience-label"
                value={value}
                onChange={onChange}
                label="Experience"
                startAdornment={
                  <InputAdornment position="start">
                    <TimelineOutlinedIcon color="primary" />
                  </InputAdornment>
                }
                renderValue={(selected) => (
                  <Typography>
                    {selected === 0 ? `${selected} Years` : `${selected}+ Years`}
                  </Typography>
                )}>
                {experienceOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {error && <TextField error helperText={error.message} />}
            </FormControl>
          )}
          rules={{ required: 'Please fill this field' }}
        />
      </div>
      <Button fullWidth variant="contained" color="primary" type="submit">
        <p className="text--md">Next</p> <EastIcon style={{ marginLeft: '20px' }} />
      </Button>
    </form>
  );
};

export default Experience;
