/* eslint-disable no-undef */
import { FileCopyOutlined } from '@mui/icons-material';
import { Button, Fab, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useStoreState } from 'easy-peasy';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { debounce } from '../../../../helper/util.helper';
import { checkForDomain, getDomainFromDb } from './domain.service';
import { storeSiteDomain } from '../../../../helper/api.helper';

const Domain = () => {
  const [domainData, setDomainData] = useState();
  const [domainAvailable, setDomainAvailable] = useState('');
  const practitionerData = useStoreState((state) => state.practitionerData);
  const { handleSubmit, control, reset, formState } = useForm();

  const checkDomainAvailability = async (domain) => {
    setDomainAvailable(await checkForDomain(domain?.toLowerCase()));
  };

  const debouncedSearch = useCallback(debounce(checkDomainAvailability, 1000), []);

  const setSiteDomain = (e, update) => {
    update(e.target.value);
    if (!domainData?.siteDomain) {
      debouncedSearch(e.target.value);
    }
  };

  const copySiteDomain = (domain) => {
    navigator.clipboard.writeText(`https://${domain}.detalks.com`);
    toast.success('copied');
  };

  const onSubmit = async (data) => {
    data.siteDomain = data.siteDomain.toLowerCase();
    const isDomainAvailable = await checkForDomain(data.siteDomain);
    if (!isDomainAvailable && !domainData?.siteDomain) {
      setDomainAvailable(false);
      return;
    }

    await toast.promise(storeSiteDomain({ siteDomain: data.siteDomain.toLowerCase() }), {
      loading: 'Saving...',
      success: 'Saved!',
      error: `Couldn't to save`
    });
    getDomainData();
  };

  useEffect(() => {
    getDomainData();
  }, []);

  const getDomainData = () => {
    getDomainFromDb(practitionerData.own_organisation).then((data) => {
      setDomainData(data);
    });
  };

  useEffect(() => {
    reset(domainData);
  }, [domainData, reset]);

  return (
    <div className="container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="heading margin-top--xxxs">DeTalks site domain</h4>
        {/* <hr /> */}
        <p className="text--sm">
          Please choose your DeTalks site domain carefully. This can NOT be changed later.
        </p>
        <div className="field margin-top margin-bottom--xs">
          <Controller
            name="siteDomain"
            control={control}
            defaultValue=""
            rules={{
              required: 'Please fill this field',
              pattern: /^[a-zA-Z0-9]*[a-zA-Z0-9]$/
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="flex-container">
                <TextField
                  label="Site domain"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={value}
                  disabled={!!domainData?.siteDomain}
                  fullWidth={false}
                  onChange={(e) => setSiteDomain(e, onChange)}
                  error={!!error || domainAvailable === false}
                  style={{ minWidth: '250px', width: `${Math.max(value?.length + 15)}ch` }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginLeft: '1px' }}>
                        <p>.detalks.com</p>
                      </InputAdornment>
                    ),
                    inputProps: {
                      style: { textAlign: 'right' }
                    }
                  }}
                  helperText={
                    error
                      ? error.message
                      : value && domainAvailable !== ''
                        ? domainAvailable
                          ? 'Domain Available'
                          : 'Domain not available'
                        : 'Tip: Choose a domain name which is easier for your clients to remember.'
                  }
                />
                {domainData?.siteDomain && (
                  <div className="copy-icon margin-top--xxs">
                    <FileCopyOutlined onClick={() => copySiteDomain(value)} />
                  </div>
                )}
              </div>
            )}
          />
        </div>
        <div>
          <Button
            style={{ width: '120px', marginTop: '10px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formState.isValid || !!domainData?.siteDomain}>
            Confirm
          </Button>
        </div>
      </form>
      <style jsx>{`
        .heading {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 10px;
        }
        .container {
          padding: 10px 0px;
        }

        .field {
          // max-width: 500px;
          position: relative;
        }
        .copy-icon {
          margin-left: 10px;
          cursor: pointer;
          height: fit-content;
        }
      `}</style>
    </div>
  );
};

export default Domain;
