import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DayItem from './DayItem';
import toast from 'react-hot-toast';
import Fab from '@mui/material/Fab';
import { Helmet } from 'react-helmet';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStoreState } from 'easy-peasy';
import { collection, deleteDoc, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import LocationDetails from './LocationDetails';
import { meetingTypeEnum } from '../../helper/constants.helper';
import { database } from '../../helper/firebase.helper';
import Loader from '../../shared/components/loader/Loader';

const Availability = () => {
  const practitioner = useStoreState((state) => state.user);
  const newLocation = {
    id: doc(collection(database, 'practitioners', practitioner?.uid, 'locations')).id,
    meetingType: meetingTypeEnum.IN_PERSON,
    location: '',
    locationLink: '',
    minimumNotice: 2,
    active: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    availability: [
      {
        day: 0,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 1,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 2,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 3,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 4,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 5,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 6,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      }
    ]
  };
  const [locations, setLocations] = useState([newLocation]);
  const [isLoading, setLoading] = useState(false);
  const [availabilityChanged, setAvailabilityChanged] = useState(false);

  const fetchAvailability = async () => {
    setLoading(true);
    const data = await getDocs(
      query(
        collection(database, 'practitioners', practitioner?.uid, 'locations'),
        where('active', '==', true)
      )
    ).then((docs) => {
      const res = [];
      docs.forEach((doc) => res.push(doc.data()));
      setLoading(false);
      return res;
    });
    if (data && data.length) {
      setLocations(data);
      setLoading(false);
    } else {
      setLocations([newLocation]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailability();
  }, []);

  const handleAddLocation = () => {
    setLocations((prevAvailability) => [...prevAvailability, newLocation]);
    setAvailabilityChanged(true);
  };

  const handleDeleteLocation = (id) => {
    setLocations((prevLocations) =>
      prevLocations.map((location) =>
        location.id === id ? { ...location, active: false } : location
      )
    );
    setAvailabilityChanged(true);
  };

  const saveAvailabilityToDB = async (locations) => {
    await Promise.all(
      locations.map((location, i) =>
        setDoc(doc(database, 'practitioners', practitioner?.uid, 'locations', location.id), {
          ...location,
          order: i
        })
      )
    );
    const progressDocRef = doc(database, `practitioners/${practitioner?.uid}/details/progress`);
    await setDoc(progressDocRef, { availability: true }, { merge: true });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const invalidLocationLinks = locations.filter(
      (item) => item.locationLink && !item.locationLink.startsWith('https://maps.app.goo.gl/')
    );
    // const invalidMeetingLinks = locations.filter(
    //   (item) => item.meetingLink && !item.meetingLink.startsWith('https://meet.google.com/')
    // );
    if (invalidLocationLinks.length > 0) {
      invalidLocationLinks.forEach((item) => {
        toast.error(`Invalid Google Maps link for location: ${item.location}`);
      });
      return;
    }
    // if (invalidMeetingLinks.length > 0) {
    //   invalidMeetingLinks.forEach((item) => {
    //     toast.error(`Invalid Google Meet link for location: ${item.location}`);
    //   });
    //   return;
    // }
    toast.promise(saveAvailabilityToDB(locations), {
      loading: 'Saving availability',
      success: 'Availability saved !',
      error: (err) => `Failed to save! ${err.message}. Please try again`
    });
    setAvailabilityChanged(false);
  };

  return (
    <div style={{ padding: 'var(--space-unit)' }}>
      <Helmet>
        <title>Availability - DeTalks</title>
      </Helmet>
      {isLoading ? <Loader /> : null}
      <div className="content-div margin-top--xs">
        <form onSubmit={handleSave}>
          {locations
            ?.sort((a, b) => a.order - b.order)
            .map(
              (availabilityItem, index) =>
                availabilityItem?.active === true && (
                  <div key={index} className="margin-bottom--lg">
                    <div className="flex-container justify-between heading-div">
                      <h4 className=" margin-top--xxs">Location {index + 1} Availability</h4>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => handleDeleteLocation(availabilityItem.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>

                    <LocationDetails
                      availabilityItem={availabilityItem}
                      setLocations={setLocations}
                      index={index}
                      setAvailabilityChanged={setAvailabilityChanged}
                      locations={locations}
                    />
                    <h4 style={{ fontSize: '16px' }} className="margin-top--md">
                      Availability
                    </h4>
                    {availabilityItem?.availability?.map(({ day, periods, isActive }) => (
                      <DayItem
                        id={availabilityItem.id}
                        key={day}
                        day={day}
                        periods={periods}
                        setLocations={setLocations}
                        isActive={isActive}
                        // onDayChange={() =>
                        //   handleDayChange(availabilityItem.id, day)
                        // }
                        setAvailabilityChanged={setAvailabilityChanged}
                      />
                    ))}
                  </div>
                )
            )}

          <Button onClick={handleAddLocation}>Add new Location</Button>

          <div className="floating-button">
            <Fab
              type="submit"
              disabled={!availabilityChanged}
              style={{ width: '120px' }}
              variant="extended"
              color="primary">
              Save
            </Fab>
          </div>
        </form>
      </div>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 0px 5px 2px 0px;
        }
        .content-div {
          padding: 0px 5px 15px 0px;
        }

        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default Availability;
