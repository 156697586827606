import { useState, Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route, Navigate, NavLink } from 'react-router-dom';
import Profile from './profile/Profile';
import MessageIcon from '@mui/icons-material/Message';
import { AppBar } from '@mui/material';
import TopNav from '../shared/components/TopNav/TopNav';
import Sidebar from './shared/components/sidebar/Sidebar';
import styles from './dashboard.module.scss';
import HomeIcon from '@mui/icons-material/Home';
import clsx from 'clsx';
import Loader from '../shared/components/loader/Loader';
import Availability from './availability/Availability';
import Services from './services/Services';
import Clients from './clients/Clients';
import Bookings from './bookings/Bookings';
import MyPractice from './my-practice/MyPractice';
import OtherPractices from './other-practices/OtherPractices';
// import Messages from './messages/Messages';
import Integrations from './integrations/Integrations';

const Dashboard = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });
  const [sidebar, setSidebar] = useState(window.innerWidth > 960);

  const handleSidebar = () => {
    setSidebar((s) => !s);
  };

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <div className={styles.wrapper}>
          <TopNav showUser handleSidebar={handleSidebar} />
          <div id="dashboard-container" className={styles.homeContent}>
            <Sidebar {...{ sidebar, handleSidebar }} />
            <div className={styles.homeContainer}>
              <Routes>
                <Route index element={<Navigate to="bookings" replace />} />
                <Route path="/bookings/*" element={<Bookings />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/clients/*" element={<Clients />} />
                <Route path="/availability/*" element={<Availability />} />
                <Route path="/services/*" element={<Services />} />
                <Route path="/practice/*" element={<MyPractice />} />
                <Route path="/other-practices/*" element={<OtherPractices />} />
                {/* <Route path="/messages/*" element={<Messages />} /> */}
                <Route path="/integrations/*" element={<Integrations />} />
              </Routes>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Suspense>
  );
};

export default Dashboard;
